import React from "react";
import Form from "./components/Input/Form";
import "../src/components/Input/css/style.css";
function App() {
  return (
    <div>
      <Form />
    </div>
  );
}
export default App;
