import React from "react";
import "./css/style.css";
import "./css/style.css.map";
const Upload = (props) => {
  const fileUploadHandler = (e) => {
    props.recievedFile(e.target.files);
  };
  return (
    <div className="form-group">
      <label htmlFor="file" className="drop-container">
        <span className="drop-title">
          Drop files here
          <input
            type="file"
            id="file"
            accept="image/*,.pdf, .docx"
            onChange={fileUploadHandler}
            multiple
            required
          ></input>
        </span>
      </label>
    </div>
  );
};

export default Upload;
