import React from "react";
import "../Input/css/style.css";
import "../Input/css/style.css.map";
import "./Wrapper.css";

const Wrapper = (props) => {
  return (
    <div className="container">
      <div className="signup-content">
        <div className="signup-img">
          <img
            id="girls-images"
            src={process.env.PUBLIC_URL + "signup-img.jpg"}
            alt=""
          />
        </div>
        <div className="signup-form">
          <div className="register-form">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
