//Importing modules and styles for the form
import React, { useEffect, useState, useRef } from "react";
import "./css/style.css";
import "./css/style.css.map";
//Code for the Bank prop
const Bank = (props) => {
  let fullName = props.fullName2;
  const [allBanks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState([]);
  const accountName = useRef();
  const accOwner = useRef();
  const [isItHers, SetIsItHers] = useState(false);
  const [allBranchs, setBranchs] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [accountNameDiv, SetAccountNameDiv] = useState();
  const apiUsername = "CRDB-Victor_Masoke";
  const apiPassword = "TmdleWltdWNoaXJhbWJhQDIwMjQ";
  let apiCreds = {
    APIUsername: apiUsername,
    APIPassword: apiPassword,
  };
  //Use the USEEFFECT to FETCH the banks
  useEffect(() => {
    fetch("https://creg.co.zw/OnlineMandate/home/MandateBanks", {
      method: "POST",
      body: JSON.stringify(apiCreds),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setBanks(json[0].Banks);
      });
  }, []);

  function getBranches(bankCode) {
    let apiCreds2 = {
      APIUsername: apiUsername,
      APIPassword: apiPassword,
      BankCode: bankCode,
    };
    fetch("https://creg.co.zw/OnlineMandate/home/MandateBranches", {
      method: "POST",
      body: JSON.stringify(apiCreds2),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setBranchs(json[0].Banks);
      });
  }

  return (
    <div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="accountOwner">Account Owner :</label>
          <div className="form-select">
            <select
              name="accountOwner"
              id="accountOwner"
              required
              ref={accOwner}
              onChange={(e) => {
                if (accOwner.current.value === "ownaccount") {
                  accountName.current.value = fullName;
                  props.accountName(fullName);
                  //console.log(accountName.current.value);
                }
              }}
            >
              <option selected></option>
              <option value="ownaccount">Own Account</option>
              <option value="thirdpartyaccount">Third Party Account</option>
            </select>
            <span className="select-icon">
              <i className="zmdi zmdi-chevron-down"></i>
            </span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="accountName">Account Name :</label>
          <input
            type="text"
            name="accountName"
            id="accountName"
            required
            ref={accountName}
            onChange={(e) => {
              props.accountName(e.target.value);
              // console.log(e.target.value);
            }}
          />
        </div>
      </div>
      <section>{accountNameDiv}</section>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="bank" placeholder="Please Select Bank">
            Bank :
          </label>
          <select
            required
            onChange={(e) => {
              setSelectedBank(e.target.value);
              getBranches(e.target.value);
              props.bank(e.target.value);
            }}
          >
            <option value=""></option>
            {allBanks.map((opts, i) => (
              <option key={opts.BankCode} value={opts.BankCode}>
                {opts.BankName}
              </option>
            ))}
          </select>
          <span className="select-icon">
            <i className="zmdi zmdi-chevron-down"></i>
          </span>
        </div>
        <div className="form-group">
          <label htmlFor="branch" placeholder="Please Select Your Branch">
            Branch :
          </label>
          <div className="form-select">
            <select
              onChange={(e) => {
                setSelectedBranch(e.target.value);
                props.branch(e.target.value);
              }}
            >
              <option value=""></option>
              {allBranchs?.map((opts, i) => (
                <option key={opts.BranchCode} value={opts.BranchCode}>
                  {opts.Branch}
                </option>
              ))}
            </select>
            <span className="select-icon">
              <i className="zmdi zmdi-chevron-down"></i>
            </span>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="accountNumber">Bank Account Number :</label>
          <input
            type="accountNumber"
            name="accountNumber"
            id="accountNumber"
            onChange={(e) => {
              props.accountNumber(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="course">Account Status :</label>
          <div className="form-select">
            <select
              name="branch"
              id="branch"
              onChange={(e) => {
                props.accountStatus(e.target.value);
              }}
            >
              <option value=""></option>
              <option value="active">Active</option>
              <option value="not active">Not active</option>
            </select>
            <span className="select-icon">
              <i className="zmdi zmdi-chevron-down"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Bank;
