import React, { useState, useEffect, useRef } from "react";
import "./css/style.css";
import "./css/style.css.map";

const FormInit = (props) => {
  const [selectedPayment, setSelectedPayment] = useState("");
  let fullName = props.fullName2;
  let cdsNumber = props.cdsNumber2;
  const getData = () => {};
  useEffect(() => {
    getData();
  }, [getData]);
  const paymentChangeHandler = (event) => {
    let myPayment = event.target.value;
    setSelectedPayment(myPayment);
    props.onPaymentSelect(myPayment);
  };
  return (
    <div>
      <h2>Payment Details Registration</h2>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="state">Payment Type :</label>
          <div className="form-select">
            <select
              name="paymentType"
              id="paymentType"
              required
              default="bank"
              onChange={paymentChangeHandler}
            >
              <option value="bank">Bank</option>
            </select>
            <span className="select-icon">
              <i className="zmdi zmdi-chevron-down"></i>
            </span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="fullName">Full Name :</label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            value={fullName}
            readOnly
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="cdsNumber">LuSE ID Number :</label>
          <input
            type="text"
            name="cdsNumber"
            id="cdsNumber"
            value={cdsNumber}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="number">Telephone Number:</label>
          <input
            type="text"
            name="telNumber"
            id="telNumber"
            required
            onChange={(e) => {
              props.telNumber(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="email">Email Address :</label>
          <input
            type="email"
            name="emailAddress"
            id="emailAddress"
            required
            onChange={(e) => {
              props.emailAddress(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tipNumber">TPin :</label>
          <input
            type="tipNumber"
            name="tipNumber"
            id="tipNumber"
            required
            onChange={(e) => {
              props.TIPNumber(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default FormInit;
