//Importing of mudules and the other forms to the main form
import React, { useState, Component } from "react";
import Wrapper from "../UI/Wrapper";
import FormInit from "./FormInit";
import Bank from "./Bank";
import Mobile from "./Mobile";
import Upload from "./Upload";
import Modal from "../Verifications/Modal";
import Button from "../UI/Button";
import "./css/style.css";
import "./css/style.css.map";
//main form for saving the bank details
const Form = (props) => {
  const [cdsNumberConfirmed, setCdsNumberConfirmed] = useState();
  const [fullNameConfirmed, setfullNameConfirmed] = useState();
  const [fullNameConfirmedToBank, setfullNameConfirmedBank] = useState();
  const [bank, SetBank] = useState();
  const [branch, setBranch] = useState();
  const [accountName, setAccountName] = useState();
  const [MyResponse, setMyResponse] = useState();
  const [MyFile, setMyFile] = useState([]);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountStatus, setAccountStatus] = useState("");
  const [TipNumber, SetTipNumber] = useState("");
  const [accountOwner, setAccountOwner] = useState("");
  const [mobileNumber, SetMobilelNumber] = useState("");
  const [mnoProvider, setProvider] = useState("");
  const [btnText, setBtnText] = useState("Loading...");
  const [isApproved, setIsAprroved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [contentForm, SetContentForm] = useState(null);
  const [logInData, setLogInData] = useState([]);
  const [paymentHandlerInit, SetPaymentHandlerInit] = useState("bank");
  const [emailAddress, SetEmailAddress] = useState("");
  const [telNumber, SetTelNumber] = useState("");
  const [validateState, SetValidateState] = useState(false);
  const [jsonBack, setJsonBack] = useState();
  const [jsonBackTwo, setJsonBackTwo] = useState();
  const [ifAccountIsNull, setIfAccountIsNull] = useState();

  const getEmailAddress = (enteredText) => {
    SetEmailAddress(enteredText);
  };

  const getTelNumber = (enteredText) => {
    SetTelNumber(enteredText);
  };

  const getMobileNumber = (enteredText) => {
    SetMobilelNumber(enteredText);
  };

  const getProvider = (enteredText) => {
    setProvider(enteredText);
  };

  const getBank = (enteredText) => {
    SetBank(enteredText);
  };
  const getBranch = (enteredText) => {
    setBranch(enteredText);
  };
  const getAccountName = (enteredText) => {
    setAccountName(enteredText);
    //console.log(enteredText);
  };
  const getAccountNumber = (enteredText) => {
    setAccountNumber(enteredText);
  };
  const getAccountStatus = (enteredText) => {
    setAccountStatus(enteredText);
  };
  const getTipNumber = (enteredText) => {
    SetTipNumber(enteredText);
  };
  const paymentHandler = (enteredText) => {
    SetPaymentHandlerInit(enteredText);
    if (enteredText === "bank") {
      SetContentForm(
        <Bank
          fullName2={fullNameConfirmed}
          branch={getBranch}
          bank={getBank}
          accountName={getAccountName}
          accountNumber={getAccountNumber}
          accountStatus={getAccountStatus}
          accountOwner={accountOwnerSelected}
        />
      );
    } else if (enteredText === "mobile") {
      SetContentForm(
        <Mobile mobileNumber={getMobileNumber} provider={getProvider} />
      );
    } else if (enteredText === "") {
      SetContentForm(<p style={{ textAlign: "center" }}></p>);
    }
  };

  const accountOwnerSelected = (accountOwner) => {
    setAccountOwner(accountOwner);
    if (accountOwner === "ownaccount") {
      setfullNameConfirmedBank(fullNameConfirmed);
    }
  };

  async function modalDataHandler(cdsEntred) {
    setIsLoading(true);
    setError(null);
    setIsAprroved(false);

    const apiUsername = "CRDB-Victor_Masoke";
    const apiPassword = "TmdleWltdWNoaXJhbWJhQDIwMjQ";
    let shareholderInfo = {
      APIUsername: apiUsername,
      APIPassword: apiPassword,
      shareHolder: cdsEntred,
    };
    try {
      setBtnText("Loading..");
      const response = await fetch(
        "https://creg.co.zw/OnlineMandate/home/ShareholderSearch",
        {
          method: "POST",
          body: JSON.stringify(shareholderInfo),
          headers: {
            "Content-Type": "application/json",
          },
          "Access-Control-Allow-Origin": "*",
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setLogInData(json);
          setIsLoading(false);
          json[0].IncomingHoldingItem.map((newSetData) => {
            setfullNameConfirmed(newSetData.ShareholderName);
            setIsAprroved(newSetData.status);
            setCdsNumberConfirmed(newSetData.ShareholderNumber);
            setIfAccountIsNull(newSetData.responseMessage);
            if (ifAccountIsNull) {
              window.alert(ifAccountIsNull);
              // console.log(ifAccountIsNull);
            }
          });
        });
      if (!response.ok) {
        throw new Error("Something went wrong! Please try again");
        setIsAprroved(false);
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }

  const validateThenSend = () => {
    if (MyFile[0]) {
      if (!accountName) {
        window.alert("Please enter your account name");
      } else if (!bank) {
        window.alert("Please enter your bank name");
      } else if (!accountNumber) {
        window.alert("Please enter your account number");
      } else if (!telNumber) {
        window.alert("Please enter your telephone number");
      } else if (!emailAddress) {
        window.alert("Please enter your email address");
      } else {
        sendFileAPI();
        sendDataAPI();
        window.alert(
          "Banking details submitted successfully"
        );
      }
      
    } else {
      window.alert(
        "Please attach a copy of your NRC/ID/Passport/Executorship documents to proceed"
      );
    }
  };

  function sendDataAPI() {
    const apiUsername = "CRDB-Victor_Masoke";
    const apiPassword = "TmdleWltdWNoaXJhbWJhQDIwMjQ";
    let postData = {
      APIUsername: apiUsername,
      APIPassword: apiPassword,
      AccountName: accountName,
      Shareholder: cdsNumberConfirmed,
      BankCode: bank,
      BranchCode: branch,
      BankAccount: accountNumber,
      MobileNumber: mobileNumber,
      MnoProvider: mnoProvider,
      Email: emailAddress,
      Telephone: telNumber,
      TipNumber: TipNumber,
    };
    // console.log(postData);
   //window.alert(JSON.stringify(postData));
    try {
      const response = fetch(
        "https://creg.co.zw/OnlineMandate/home/OnlineMandatesUpdate",
        {
          method: "POST",
          body: JSON.stringify(postData),
          headers: {
            "Content-Type": "application/json",
          },
          "Access-Control-Allow-Origin": "*",
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setLogInData(json);
          setMyResponse(json[0].responseMessage);
          setJsonBack(json[0].responseMessage);
          window.alert(json[0].responseMessage);
          return true;
        });
    } catch (error) {
      return false;
      setError(error.message);
    }
  }

  function sendFileAPI() {
    try {
      for (let i = 0; i < MyFile.length; i++) {
        console.log(MyFile[i]);
        const file = MyFile[i];
        const fileExtension = file.name.split(".").pop();
        const newFileName = fullNameConfirmed + cdsNumberConfirmed + "_" + i;
        const newFileWithExtension = `${newFileName}.${fileExtension}`;
        let formData = new FormData();
        const blob = new Blob([file], {type: file.type})
        
        formData.append("file", blob, newFileWithExtension);


         const response = fetch(
           "https://creg.co.zw/OnlineMandate/home/UploadFiles",
           {
             method: "POST",
             body: formData,
           }
         )
           .then((response) =>response.json())
          .then((json) => {
            //console.log(response);
            return true;
           });

      }
    } catch (error) {
      return false;
      setError(error.message);
    }
  }
  const fileDownloadHandler = (myFile) => {
    setMyFile(myFile);
  };

  return (
    <div>
      <Wrapper>
        <form>
          <section>
            {!isApproved && <Modal onLog={modalDataHandler} btntxt={btnText} />}
          </section>
          <section>
            {isApproved && (
              <div>
                <FormInit
                  fullName2={fullNameConfirmed}
                  cdsNumber2={cdsNumberConfirmed}
                  onPaymentSelect={paymentHandler}
                  telNumber={getTelNumber}
                  emailAddress={getEmailAddress}
                  TIPNumber={getTipNumber}
                />
                <section>
                  <Bank
                    fullName2={fullNameConfirmed}
                    branch={getBranch}
                    bank={getBank}
                    accountName={getAccountName}
                    accountNumber={getAccountNumber}
                    accountStatus={getAccountStatus}
                    accountOwner={accountOwnerSelected}
                    TIPNumber={getTipNumber}
                  />
                </section>
                <Upload recievedFile={fileDownloadHandler} />
                <Button onClick={validateThenSend} type="submit">
                  Submit
                </Button>
              </div>
            )}
          </section>
        </form>
      </Wrapper>
    </div>
  );
};
export default Form;
