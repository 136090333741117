import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import "../Input/css/style.css";
import Card from "../UI/Card";
import classes from "./Modal.module.css";
import Button from "../UI/Button";
import "../Input/css/style.css.map";
const Backdrop = (props) => {
  return <div className={classes.backdrop} />;
};
const ModalOverlay = (props) => {
  const CdsNumber = useRef();
  const [btnText, setBtnText] = useState("Verify");
  const modalClickHandler = () => {
    let logCdsNumber = CdsNumber.current.value * -1;
    props.onLog(logCdsNumber.toString());
    setBtnText(props.btnTxt);
  };
  return (
    <Card className={classes.modal}>
      <header className={classes.header}>
        <h3>Provide your LuSE CSD Number to Capture Your Details</h3>
      </header>
      <div className={classes.content}>
        <div className="form-group">
          <label htmlFor="cdsNumber">LuSE ID Number :<span color="red">field requires numbers/numeric characters only</span></label>
          <input type="number" name="cdsNumber" id="cdsNumber" ref={CdsNumber} />
        </div>
      </div>
      <footer className={classes.actions}>
        <Button onClick={modalClickHandler}>{btnText}</Button>
      </footer>
    </Card>
  );
};
const Modal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay onLog={props.onLog} btnTxt={props.btntxt} />,
        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );
};
export default Modal;
