//Import the React Module and use state and the UseEffect for using the tetch and post methods
import React, { useState, useEffect } from "react";
import "./css/style.css";
import "./css/style.css.map";
//Creating a Mobile prop to send the data to the API
const Mobile = (props) => {
  const [provider, SetProvider] = useState();
  const [allProviders, SetAllProviders] = useState([]);
  const apiUsername = "CRDB-Victor_Masoke";
  const apiPassword = "TmdleWltdWNoaXJhbWJhQDIwMjQ";
  let apiCreds = {
    APIUsername: apiUsername,
    APIPassword: apiPassword,
  };
  //Use the use Effect to get the MNO LIST
  useEffect(() => {
    fetch("https://creg.co.zw/OnlineMandate/home/MNOList", {
      method: "POST",
      body: JSON.stringify(apiCreds),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        SetAllProviders(json[0].IncomingMNOItem);
      });
  }, []);

  return (
    <div className="form-row">
      <div className="form-group">
        <label htmlFor="Wallet_Number">Mobile Network Provider: </label>
        <select
          onChange={(e) => {
            props.provider(e.target.value);
          }}
        >
          <option value=""></option>

          {allProviders?.map((opts, i) => (
            <option key={opts.MNO} value={opts.MNO}>
              {opts.MNO}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="Mobile_Number">Wallet Number: </label>
        <input
          type="number"
          name="mobile"
          id="mobile"
          required
          placeholder="+260"
          onChange={(e) => {
            props.mobileNumber(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default Mobile;
